<template>
  <v-sheet class="page-sheet rounded-20">
    <v-card class="yellow lighten-5 fill-height rounded-20">
      <v-card-title>
        <v-spacer></v-spacer>
        <i18n path="license.result" />
        <v-spacer></v-spacer>
        <v-btn icon color="error" to="/">
          <v-icon large>mdi-close-circle</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row v-if="result" justify="center">
          <v-col cols="4">
            <v-row class="green lighten-5 elevation-10 pa-4 back-lic-1">
              <v-col cols="12" class="d-flex align-center">
                <i18n path="license.key" class="mr-4 text-h5" />
                <div class="text-h4 result-2-col">
                  <span id="licenseKey">{{result.hash}}</span>
                </div>
                <v-tooltip bottom>
                  <template #activator="{on, attrs}">
                    <v-btn icon @click="getHashCopy" v-on="on" v-bind="attrs">
                      <v-icon color="blue">mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  <i18n path="license.copy" />
                </v-tooltip>
              </v-col>
              <v-col cols="12" class="d-flex align-center">
                <i18n path="license.status" class="mr-4" />
                <span class="text-h5 result-2-col">{{result.status}}</span>
              </v-col>
              <v-col cols="12" class="d-flex align-center">
                <i18n path="license.duration" />
                <span class="text-h5 mr-4 result-2-col">{{result.duration}}</span>
                <i18n path="license.durdays" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-sheet>
</template>

<script>
export default {
  name: "ResultLicense",
  data() {
    return {
      result: null,
    };
  },
  methods: {
    getHashCopy() {
      try {
        let range = document.createRange();
        range.selectNode(document.getElementById("licenseKey"));
        //console.log(range);
        window.getSelection().removeAllRanges(); // clear current selection
        window.getSelection().addRange(range); // to select text
        document.execCommand("copy");
        window.getSelection().removeAllRanges(); // to deselect
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.$api
      .apiGetRequest("v1/license/result/" + this.$route.params.id)
      .then((r) => (this.result = { ...r }));
  },
};
</script>

<style scoped>
.rounded-20 {
  border-radius: 20px !important;
}
.center-in-div {
  margin: auto;
}
.result-2-col {
  width: 100%;
  text-align: right;
}
.back-lic-1 {
  background: url("~@/assets/img/lic15.png") no-repeat;
  background-position: center;
  background-size: contain;
}

</style>